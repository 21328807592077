<template>
	<div class="dataShow">
		
		<div class="qcmain">
			<div class="qcTable">
				<div class="servch">
					<div class="addAccout">
						<div class="wenzi">已授权店铺：</div>
						<el-select v-model="AuthShopsValue" @visible-change="onVIsible" @change="onAdddierci" placeholder="请选择">
							<el-option v-for="item in AuthShopsList" :key="item.advertiser_id" :label="item.advertiser_name" :value="item.advertiser_id"></el-option>
						</el-select>
					</div>
					<div class="addAccout er" v-if="AuthShopsValue">
						<div class="wenzi">广告账户选择：</div>
						<el-select v-model="ShopAdvertisersValue" @change="onAdvertisers" placeholder="请选择">
							<el-option v-for="item in ShopAdvertisersList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="qcTime">
						<div class="wenzi">日期选择：</div>
						<el-date-picker
							popper-class="qcitemsj"
							format="yyyy-MM-dd"
               				value-format="yyyy-MM-dd"
							:editable="false"
                			:clearable="false"
							v-model="qcTimevalue"
							@change="onTimeChange"
							type="daterange"
							range-separator="~"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							:picker-options="pickerOptions">
						</el-date-picker>
					</div>
					<el-button class="pcsousuo" type="primary" @click="ongetReport">搜索</el-button>
				</div>
				<div class="tablist" v-if="ReportList.stat_cost">
					<div class="tabdan" v-if="ReportList.stat_cost">
						<div class="iconbjtu"><i class="iconfont icon-xiaohao"></i></div>
						<div class="text1">
							消耗（元）
							<el-tooltip class="item" effect="dark" content="广告的消耗" placement="top">
								<i class="iconfont icon-question-circle-fill"></i>
							</el-tooltip>
						</div>
						<div class="num">{{ReportList.stat_cost.toFixed(2)}}</div>
						<div class="prompt">
							<span class="hui">与上一周期相比</span>
							<span :class="(ReportList.stat_cost - pre_count.stat_cost)>0 ? 'hong' : 'lv'" v-if="pre_count.stat_cost">
								<i class="iconfont " :class="(ReportList.stat_cost - pre_count.stat_cost)>0 ? 'icon-tubiaozhizuo-' : 'icon-tubiaozhizuo-1'"></i>
								{{(Math.abs(ReportList.stat_cost - pre_count.stat_cost)/100).toFixed(2)}}%
							</span>
							<span v-else>- -</span>
						</div>
					</div>
					<div class="tabdan" v-if="ReportList.show_cnt">
						<div class="iconbjtu"><i class="iconfont icon-zhanshi"></i></div>
						<div class="text1">
							展示次数
							<el-tooltip class="item" effect="dark" content="广告展示给用户的次数" placement="top">
								<i class="iconfont icon-question-circle-fill"></i>
							</el-tooltip>
						</div>
						<div class="num">{{ReportList.show_cnt}}</div>
						<div class="prompt">
							<span class="hui">与上一周期相比</span>
							<span :class="(ReportList.show_cnt - pre_count.show_cnt)>0 ? 'hong' : 'lv'" v-if="pre_count.stat_cost">
								<i class="iconfont " :class="(ReportList.show_cnt - pre_count.show_cnt)>0 ? 'icon-tubiaozhizuo-' : 'icon-tubiaozhizuo-1'"></i>
								{{(Math.abs(ReportList.show_cnt - pre_count.show_cnt)/100).toFixed(2) ? (Math.abs(ReportList.show_cnt - pre_count.show_cnt)/100).toFixed(2) : '--'}}%
							</span>
							<span v-else>- -</span>
						</div>
					</div>
					<div class="tabdan" v-if="ReportList.ctr">
						<div class="iconbjtu"><i class="iconfont icon-dianji"></i></div>
						<div class="text1">
							点击率
							<el-tooltip class="item" effect="dark" content="点击次数/展示次数*100%" placement="top">
								<i class="iconfont icon-question-circle-fill"></i>
							</el-tooltip>
						</div>
						<div class="num">{{ReportList.ctr}}%</div>
						<div class="prompt">
							<span class="hui">与上一周期相比</span>
							<span :class="(ReportList.ctr - pre_count.ctr)>0 ? 'hong' : 'lv'" v-if="pre_count.stat_cost">
								<i class="iconfont " :class="(ReportList.ctr - pre_count.ctr)>0 ? 'icon-tubiaozhizuo-' : 'icon-tubiaozhizuo-1'"></i>
								{{(Math.abs(ReportList.ctr - pre_count.ctr)/100).toFixed(2)}}%
							</span>
							<span v-else>- -</span>
						</div>
					</div>
					<div class="tabdan" v-if="ReportList.pay_order_count">
						<div class="iconbjtu"><i class="iconfont icon-order"></i></div>
						<div class="text1">
							成交订单数
							<el-tooltip class="item" effect="dark" content="广告带来的成交订单数（包含在线支付成功和货到付款已确认的订单）" placement="top">
								<i class="iconfont icon-question-circle-fill"></i>
							</el-tooltip>
						</div>
						<div class="num">{{ReportList.pay_order_count}}</div>
						<div class="prompt">
							<span class="hui">与上一周期相比</span>
							<span :class="(ReportList.pay_order_count - pre_count.pay_order_count)>0 ? 'hong' : 'lv'" v-if="pre_count.stat_cost">
								<i class="iconfont " :class="(ReportList.pay_order_count - pre_count.pay_order_count)>0 ? 'icon-tubiaozhizuo-' : 'icon-tubiaozhizuo-1'"></i>
								{{(Math.abs(ReportList.pay_order_count - pre_count.pay_order_count)/100).toFixed(2)}}%
							</span>
							<span v-else>- -</span>
						</div>
					</div>
					<div class="tabdan" v-if="ReportList.pay_order_amount">
						<div class="iconbjtu"><i class="iconfont icon-dingdanjine"></i></div>
						<div class="text1">
							成交订单金额
							<el-tooltip class="item" effect="dark" content="广告带来的成交订单金额，单位：元（包含在线支付成功和货到付款已确认的订单）" placement="top">
								<i class="iconfont icon-question-circle-fill"></i>
							</el-tooltip>
						</div>
						<div class="num">{{ReportList.pay_order_amount}}</div>
						<div class="prompt">
							<span class="hui">与上一周期相比</span>
							<span :class="(ReportList.pay_order_amount - pre_count.pay_order_amount)>0 ? 'hong' : 'lv'" v-if="pre_count.stat_cost">
								<i class="iconfont " :class="(ReportList.pay_order_amount - pre_count.pay_order_amount)>0 ? 'icon-tubiaozhizuo-' : 'icon-tubiaozhizuo-1'"></i>
								{{(Math.abs(ReportList.pay_order_amount - pre_count.pay_order_amount)/100).toFixed(2)}}%
							</span>
							<span v-else>- -</span>
						</div>
					</div>
				</div>
				<div v-else style="margin:20px auto; text-align: center;">暂无数据</div>
			</div>
			<!-- <div class="qczhanghu">
				<div class="dan">
					<div class="title"><i class="iconfont icon-zhanghuguanli"></i>今日消耗</div>
					<div class="biaoge">
						<div class="liab">
							<div class="text">短视频/图文带货</div>
							<div class="num">123.123.12元</div>
						</div>
						<div class="liab">
							<div class="text">直播带货</div>
							<div class="num">-</div>
						</div>
						<div class="liab">
							<div class="text">小店随心推</div>
							<div class="num">0元</div>
						</div>
					</div>
					
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import moment from "moment";//时间解析
export default {
	props:['accoutValue'],
	data () {
		return {
			AuthShopsValue:'',//第二次账户选择
			AuthShopsList:[],//第二次账户列表
			ShopAdvertisersValue:'',//第三次选择账户
			ShopAdvertisersList:[],//第三次账户列表
			qcTimevalue:[],//日期
			pickerOptions:{
				disabledDate(time) {
                	return time.getTime() > Date.now() - 8.64e6
            	}
			},
			start_date:'',
			end_date:'',
			start_datejia:'',
			end_datejia:'',
			ReportList:{},
			pre_count:{},//上周
		}
	},
	watch:{
		accoutValue(e){
			this.ongetAuthShops()
		}
	},
	
	mounted(){
		this.getTimeFn()
		this.onTimeChange();
	},

	methods: {
		//第二次选择账户执行选择
		onAdddierci(){
			this.ongetShopAdvertisers()
		},
		//第二次选择账户调取接口
		onVIsible($event){
			if($event){
				if(!this.accoutValue){
					this.$message({message: '请先选择授权店铺',type: 'warning'});
				}
			}
		},
		// 第二次选择账户调取接口
		ongetAuthShops(){
			var param = {
				page:1,
				size:20,
				auth_id:this.accoutValue
			}
			this.$service.get(this.$api.getAuthShops,param, (res)=> {
				if(res.code == '200'){
					this.AuthShopsList = res.data.list
				}
			})
		},
		// 第三次选择账户接口
		ongetShopAdvertisers(){
			var param = {
				advertiser_id:this.AuthShopsValue,
				auth_id:this.accoutValue
			}
			this.$service.get(this.$api.getShopAdvertisers,param, (res)=> {
				if(res.code == '200'){
					this.ShopAdvertisersList = res.data
				}
			})
		},
		//第三次次选择账户执行选择
		onAdvertisers(){
			this.ongetReport()
		},
		// 选择完毕后调取账户数据
		ongetReport(){
			this.ReportList = []
			if(this.ShopAdvertisersValue == ''){this.$message({message: '请选择授权店铺',type: 'warning'}); return}
			if(this.accoutValue == ''){this.$message({message: '请选择广告账户',type: 'warning'}); return}
			var reprodata = {
				accoutValue:this.accoutValue,
				ShopAdvertisersValue:this.ShopAdvertisersValue
			}
			console.log(reprodata)
			localStorage.setItem('REPRO-VALUE', JSON.stringify(reprodata))
			var param = {
				advertiser_id:this.ShopAdvertisersValue,
				auth_id:this.accoutValue,
				start_date:this.start_date,
				end_date:this.end_date,
			}
			this.$service.get(this.$api.getReport,param, (res)=> {
				if(res.code == '200'){
					if(!res.data.count.click_cnt){this.$message({message: '暂未获取到日期内数据',type: 'warning'});}
					this.ReportList = res.data.count
					this.pre_count = res.data.pre_count
					console.log(this.pre_count)
				}
			})
		},
		// 选择完毕后调取账户数据
		// ongetReportjia(){
		// 	var param = {
		// 		advertiser_id:this.ShopAdvertisersValue,
		// 		auth_id:this.accoutValue,
		// 		start_date:this.start_datejia,
		// 		end_date:this.end_datejia,
		// 	}
		// 	this.$service.get(this.$api.getReport,param, (res)=> {
		// 		if(res.code == '200'){
		// 			// var listdata = {
		// 			// 	ctr:0,
		// 			// 	click_cnt:0,
		// 			// 	pay_order_count:0,
		// 			// 	pay_order_amount:0,
		// 			// }
		// 			// var stat_cost =[]//消耗
		// 			// var show_cnt =[]//展示次数
		// 			// var click_cnt =[]//点击次数
		// 			// var pay_order_count =[]//成交订单数
		// 			// var pay_order_amount=[]//成交订单金额
		// 			// res.data.list.forEach((v,k) => {
		// 			// 	stat_cost.push(v.stat_cost)
		// 			// 	show_cnt.push(v.show_cnt)
		// 			// 	click_cnt.push(v.click_cnt)
		// 			// 	pay_order_count.push(v.pay_order_count)
		// 			// 	pay_order_amount.push(v.pay_order_amount)
		// 			// });
		// 			// stat_cost.forEach(function(val, idx, arr) {listdata.stat_cost += val}, 0);//消耗
		// 			// show_cnt.forEach(function(val, idx, arr) {listdata.show_cnt += val}, 0);//展示次数
		// 			// click_cnt.forEach(function(val, idx, arr) {listdata.click_cnt += val}, 0);//点击次数
		// 			// pay_order_count.forEach(function(val, idx, arr) {listdata.pay_order_count += val}, 0);//成交订单数
		// 			// pay_order_amount.forEach(function(val, idx, arr) {listdata.pay_order_amount += val}, 0);//成交订单金额
		// 			// listdata.ctr = listdata.show_cnt/listdata.click_cnt
		// 			// console.log()
		// 			this.ReportList = res.data.count
		// 		}
		// 	})
		// },
		//时间选择
		onTimeChange(){
			this.start_date = this.qcTimevalue ? moment(this.qcTimevalue[0]).format("YYYY-MM-DD") : "",
			this.end_date = this.qcTimevalue ? moment(this.qcTimevalue[1]).format("YYYY-MM-DD") : ""
			// var xiangcha = this.start_date - this.end_date 
			// this.start_datejia = 
			// this.end_datejia = 
			if(this.ShopAdvertisersValue){
				this.ongetReport()
			}
		},
		getTimeFn(){
			this.qcTimevalue = [this.addtimeDate(-7),this.addtimeDate(0)]
			// var end = new Date(this.qcTimevalue[0].replace(/-/g, "/"))//开始时间
			// var start = new Date(this.qcTimevalue[1].replace(/-/g, "/"))//结束时间
			// var xiangcha = start.getTime() - end.getTime()
			// var xcshijian = Math.floor(xiangcha/ (24 * 3600 * 1000))
			// this.start_datejia =  moment(end).format("YYYY-MM-DD")
			// this.end_datejia =  moment(end).format("YYYY-MM-DD")
			// var endjia = new Date(this.end_datejia.replace(/-/g, "/")) - xcshijian//开始时间
		},
		
		addtimeDate(days){ 
			var d=new Date(); 
			d.setDate(d.getDate()+days); 
			var m=d.getMonth()+1; 
			return d.getFullYear()+'-'+m+'-'+d.getDate(); 
		},
		set_time(str){
			var n = parseInt(str)*1000;
			var D = new Date(n);
			var year = D.getFullYear();//四位数年份

			var month = D.getMonth()+1;//月份(0-11),0为一月份
			month = month<10?('0'+month):month;

			var day = D.getDate();//月的某一天(1-31)
			day = day<10?('0'+day):day;

			var hours = D.getHours();//小时(0-23)
			hours = hours<10?('0'+hours):hours;

			var minutes = D.getMinutes();//分钟(0-59)
			minutes = minutes<10?('0'+minutes):minutes;

			// var seconds = D.getSeconds();//秒(0-59)
			// seconds = seconds<10?('0'+seconds):seconds;
			// var week = D.getDay();//周几(0-6),0为周日
			// var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

			var now_time = year+'-'+month+'-'+day+' '+hours+':'+minutes;
			return now_time;
		}
	}
}
</script>

<style lang="scss" scoped>
.dataShow{
	.servch{
		display: flex;
		align-items: center;
		.addAccout{
			align-items: center;
			display: flex;
			&.er{
				margin-left: 20px;
			}
		}
		.pcsousuo{
			margin-left:10px;
		}
	}
	.qcmain{
		display: flex;
		justify-content: space-between;
		.qcTable{
			margin-right: 0px;
			flex:1;
			.qcTime{
				margin-left: 20px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
			.tablist{
				display: flex;
				flex-wrap: wrap;
				.tabdan{
					width: 320px;
					height: 200px;
					margin-right: 30px;
					background: #f5f5f5;
					border-radius: 10px;
					margin-top: 30px;
					text-align: center;
					cursor: pointer;
					position: relative;
					overflow: hidden;
					.iconbjtu{
						color: #7290ff;
						position: absolute;
						bottom: -30px;
						right: -20px;
						opacity: .1;
						i{
							font-size: 120px;
						}
					}
					&:hover{
						background: #2251db;
						color: #fff;
					}
					.text1{
						margin-top: 30px;
						font-size: 20px;
						i{
							font-size: 20px;
							color: #7290ff;
						}
						z-index: 1;
					}
					.num{
						font-size: 42px;
						font-weight: 500;
						// color: #2251db;
						margin-top: 10px;
					}
					.prompt{
						font-size: 16px;
						margin-top: 10px;
						.hong{
							color: #f00;
							font-size: 24px;
						}
						.lv{
							color: #45c271;
							font-size: 24px;
						}
					}
				}
			}
		}
		.qczhanghu{
			width: 380px;
			border-radius: 10px;
			border: 1px solid #ededed;
			.dan{
				padding: 30px 0;
				margin:0 30px;
				&:first-child{
					border-bottom: 1px solid #ededed;
				}
				.title{
					font-size: 22px;
					color: #2251db;
					font-weight: 500;
					margin-bottom: 20px;
					i{
						font-size: 22px;
						margin-right: 10px;
					}
				}
				.text{
					font-size: 16px;
					color: #999;
					i{
						font-size: 16px;
						color: #7290ff;
						margin-left: 5px;
					}
				}
				.num{
					font-size: 32px;
					font-weight: 500;
				}
				.biaoge{
					.liab{
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						.text{
							width: 140px;
						}
						.num{
							font-size: 22px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.dataShow{
		padding-bottom: .2rem;
		.qcmain{
			display: block;
			.qcTable{
				.servch{
					display: block;
					.wenzi{
						width: 1.9rem;
					}
					.addAccout{
						margin-left: 0;
						.el-select{
							width: 5rem;
						}
						&.er{
							margin-top: .25rem;
						}
					}
					.qcTime{
						margin-left: 0;
						margin-top: .25rem;
						justify-content: flex-start;
						.el-date-editor{
							width: 5rem;
						}
					}
				}
				.tablist{
					justify-content: space-between;
					.tabdan{
						width: 3.4rem;
						height: 2.6rem;
						margin: 0;
						margin-top: .3rem;
						.iconbjtu{
							i{
								font-size: 2rem;
							}
						}
						.text1{
							margin-top: .4rem;
							font-size: .3rem;
							i{
								font-size: .3rem;
							}
						}
						.num{
							margin-top: .1rem;
							font-size: .5rem;
						}
						.prompt{
							font-size: .2rem;
							margin-top: .1rem;
							.hong{
								font-size: .27rem;
							}
							.lv{
								font-size: .27rem;
							}
						}
					}
				}
			}
			.qczhanghu{
				margin-top: .3rem;
				.dan{
					padding: .3rem 0;
					margin: 0 .3rem;
					.title{
						font-size: .3rem;
						margin-bottom: .2rem;
						i{
							font-size: .34rem;
						}
					}
					.text{
						font-size: .26rem;
					}
					.num{
						font-size: .6rem;
					}
					.biaoge{
						.liab{
							.text{
								width: 2.2rem;
							}
							.num{
								font-size: .4rem;
							}
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
@media screen and (max-width: 750px) {
.qcitemsj{
	.el-date-range-picker .el-picker-panel__body{
		width: 400px !important;
	}
	.el-picker-panel__body-wrapper{
		width: 400px;
	}
	&.el-picker-panel{
		width: 7rem;
		overflow-x: auto;
		
		.el-date-table th{
			padding: 2px;
		}
	}
}
}

</style>